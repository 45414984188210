



































import { Component, Vue } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import Alert from '@/components/Alert.vue'
import Button from '@/components/Button.vue'
import Dialog from '@/components/Dialog.vue'
import Content from '@/components/Content.vue'
import ProductCard from '@/components/ProductCard.vue'
import { Product } from '@/models/Product'
import { firestore } from 'firebase/app'
import { ProductConverter } from '@/models/converters/ProductConverter'
import { formatMoney } from '@/services/NumberService'
import { mainStore } from '@/store'

@Component({
  components: {
    Loading,
    Alert,
    Button,
    Dialog,
    Content,
    ProductCard
  }
})
export default class Products extends Vue {
  loading: boolean = false
  products: Product[] = []

  async created() {
    if (mainStore.products.length > 0) {
      this.products = mainStore.products
      return
    }

    this.loading = true

    this.products = (
      await firestore()
        .collection('products')
        .withConverter(new ProductConverter())
        .where('listed', '==', true)
        .where('deleted', '==', false)
        .orderBy('order', 'asc')
        .get()
    ).docs.map((doc) => doc.data())
    mainStore.setProducts(this.products)

    this.loading = false
  }

  formatMoney(amount: number) {
    return formatMoney(amount)
  }
}
