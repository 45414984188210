




























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ProductCard extends Vue {
  @Prop()
  imageUrl: string

  @Prop()
  name: string

  @Prop()
  description: string

  @Prop()
  fromAmount: number

  @Prop()
  amountDetails: string

  @Prop({ default: 'object-cover' })
  classes: string
}
