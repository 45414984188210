






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Alert extends Vue {
  @Prop()
  text: string
}
